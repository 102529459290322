import React from 'react';
import { useSelector } from 'react-redux';
import { DESC_SORT_ORDER, ProjectType } from '@poly/constants';
import { CHILD_PROJECT_ELASTIC_QUERY } from '@poly/client-utils';
import {
  Loader,
  mapConfigTableProps,
  useTableSortingWrapper,
} from '@poly/site-ui';
import { WorkOrdersTabTable } from '../../../components/property/tabs/WorkOrdersTabTable.js';
import { noDataToDisplayWidget } from '../../../util/general.js';
import { useSupplierProjectsQuery } from '../../../hooks/suppliers.js';
import { projectsColumns } from '../../property/tabs/projects.js';

const mapActiveTabToWoQuery = {
  wos: {
    bool: {
      must: { match: { type: ProjectType.WORK_ORDER } },
      must_not: CHILD_PROJECT_ELASTIC_QUERY,
    },
  },
  recurring: CHILD_PROJECT_ELASTIC_QUERY,
};

export default function (props) {
  const supplierId = useSelector((state) => state.location.payload.id);

  const query = useSelector(
    (state) => mapActiveTabToWoQuery[state.suppliers.activeTab],
  );

  const { sort, setSorting, sorting, valuesToSort } = useTableSortingWrapper({
    tableConfig: projectsColumns,
    defaultOrder: DESC_SORT_ORDER,
    defaultColumn: 1,
    valuesToSortKey: 3,
    sortQueryOptionOrder: 4,
  });

  const { projects, loading } = useSupplierProjectsQuery({
    supplierId,
    query,
    sort,
  });

  if (loading) return <Loader />;
  if (!projects.length) return noDataToDisplayWidget('Projects')();

  const tableProps = mapConfigTableProps(projects, projectsColumns);

  return (
    <WorkOrdersTabTable
      {...props}
      {...tableProps}
      setSorting={setSorting}
      sorting={sorting}
      valuesToSort={valuesToSort}
    />
  );
}
