import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Loader,
  mapConfigTableProps,
  projectStatusUITransform,
  useTableSortingWrapper,
} from '@poly/site-ui';
import { commonSortQuery, keywordSortQuery } from '@poly/client-utils';
import { DESC_SORT_ORDER } from '@poly/constants';

import { WorkOrdersTabTable } from '../../../components/property/tabs/WorkOrdersTabTable.js';
import { projectFormatter } from '../../../util/project-utils.js';
import { dateFormatter } from '../../../util/job-costs.js';
import {
  fieldFormatter,
  noDataToDisplayWidget,
} from '../../../util/general.js';
import { usePropertyProjects } from '../../../hooks/property/projects.js';

export const projectsColumns = [
  [
    'WO #',
    R.prop('projectId'),
    projectFormatter,
    R.prop('projectId'),
    keywordSortQuery(['projectId']),
  ],
  [
    'Description',
    R.prop('description'),
    fieldFormatter,
    R.prop('description'),
    keywordSortQuery(['description']),
  ],
  [
    'End Date',
    R.prop('endDate'),
    dateFormatter,
    R.prop('endDate'),
    commonSortQuery(['endDate']),
  ],
  [
    'Status',
    R.identity,
    projectStatusUITransform,
    R.prop('status'),
    commonSortQuery(['status']),
  ],
];

export default function (props) {
  const propertyId = useSelector((state) => state.location.payload.id);

  const { sort, setSorting, sorting, valuesToSort } = useTableSortingWrapper({
    tableConfig: projectsColumns,
    defaultOrder: DESC_SORT_ORDER,
    defaultColumn: 1,
    valuesToSortKey: 3,
    sortQueryOptionOrder: 4,
  });

  const { projects, projectsLoading } = usePropertyProjects({
    propertyId,
    sort,
  });

  if (projectsLoading) return <Loader />;

  if (!projects.length) return noDataToDisplayWidget('Projects')();

  const tableProps = mapConfigTableProps(projects, projectsColumns);

  return (
    <WorkOrdersTabTable
      {...props}
      {...tableProps}
      setSorting={setSorting}
      sorting={sorting}
      valuesToSort={valuesToSort}
    />
  );
}
